import { defineStore } from 'pinia';

export const useGlobalVariableStore = defineStore('global_variables', {
  state: () => ({
    isShowFromforPaymentPage: true,
    checkoutSDKLink: '',
    socketConnection: () => {},
    wsConnectionUrl: '',
  }),
  getters: {
    showFromforPaymentPage: (state) => state.isShowFromforPaymentPage,
    getCheckoutSDKLink: (state) => state.checkoutSDKLink,
    getWSConnectionInstance: (state) => state.socketConnection,
    getWSConnectionUrl: (state) => state.wsConnectionUrl,
  },
  actions: {
    setIsShowFromForPaymentPage(val) {
      this.isShowFromforPaymentPage = val;
    },
    setCheckoutSDKLink(val) {
      this.checkoutSDKLink = val;
    },
    setSocketConnectionInstance(val) {
      this.socketConnection = val;
    },
    setWSConnectionUrl(val) {
      this.wsConnectionUrl = val;
    },
  },
});
