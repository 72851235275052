import { i18n } from '@/plugins/i18n';

export const API_ORIGIN = localStorage.getItem('VUE_APP_CORE_SERVER_ORIGIN');
export const REAL_ESTATE_API_GATEWAY_URL = localStorage.getItem(
  'VUE_APP_REAL_ESTATE_API_GATEWAY_URL',
);
export const MERCHANT_ID = localStorage.getItem('MERCHANT_ID');
export const BASE_API_URL = API_ORIGIN + '/api/v1';
export const BASE_CHECKOUT_API = API_ORIGIN + '/checkout/v1';
export const BASE_REAL_ESTATE_API = REAL_ESTATE_API_GATEWAY_URL + '/pbl/api/v1';

export const estateInterceptor = [
  config => {
    config.headers.Accept = 'application/json';
    config.headers['Content-Type'] = 'application/json';
    config.headers['Accept-Language'] = i18n.global.locale;
    config.headers['X-Merchant'] = MERCHANT_ID;

    return config;
  },
  error => Promise.reject(error),
];
